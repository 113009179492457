html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
.container {
  margin: 65px auto 0 auto;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-container {
  position: fixed;
  justify-content: space-between;
}
